@font-face {
  font-family: 'Formula1-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Formula1-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Formula1-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Formula1-Bold.otf') format('truetype');
}

html {
  color-scheme: dark;
  font-size: min(max(16px, 4vw), 18px);
}

body,
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: #141312;
  color: #b5b5b5;
  font-family: 'Formula1-Regular', 'Fira Sans', Verdana, sans-serif;
}

a {
  text-decoration: none;
  color: #b5b5b5;
}

a:hover {
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Formula1-Bold', 'Fira Sans', Verdana, sans-serif;
  font-weight: normal;
}

.timer-wrapper > div {
  margin: auto;
}

.timer-wrapper path {
  transition: stroke-dashoffset 0.5s linear;
}

.pure-button {
  background-color: #b5b5b5;
  color: #000;
}
.pure-button.active {
  background-color: #e10600;
  color: #fff;
}
